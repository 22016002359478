import React from 'react';
import '../css/nav.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Nav = () => {
  const { t } = useTranslation();
  return (
    <nav>
      <ul className='nav-list'>
        <li className='nav-list-item'>
          <Link to='/'>{t('home')}</Link>
        </li>
        <li className='nav-list-item'>
          <Link to='/services'>{t('services')}</Link>
          <div className='dropdown'>
            <ul className='nested-list'>
              <li>
                <Link to='/services/voice'>{t('voice')}</Link>{' '}
              </li>
              <li>
                {' '}
                <Link to='/services/sms'>{t('sms')}</Link>
              </li>
            </ul>
          </div>
        </li>
        <li className='nav-list-item'>
          <Link to='/about-us'>{t('about-us')}</Link>
        </li>
        <li className='nav-list-item'>
          <Link to='/contact-us'>{t('contact-us')}</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
