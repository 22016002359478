import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

const CallContext = React.createContext({
  data: [],
  setData: [],
  dataService: [],
  setDataService: [],
  dataSms: [],
  setDataSms: [],
  dataVoice: [],
  setDataVoice: [],
  dataAbout: [],
  setDataAbout: [],
  dataContact: [],
  setDataContact: [],
  dataWork: [],
  setDataWork: [],
});

export const CallProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [dataService, setDataService] = useState({});
  const [dataSms, setDataSms] = useState({});
  const [dataVoice, setDataVoice] = useState({});
  const [dataAbout, setDataAbout] = useState({});
  const [dataContact, setDataContact] = useState({});
  const [dataWork, setDataWork] = useState({});

  const getHomePageData = () => {
    axios
      .get('https://call4china-default-rtdb.firebaseio.com/homePage.json')
      .then((res) => {
        const tempData = [];
        for (const key in res.data) {
          const data = {
            id: key,
            bannerImage: res.data[key].bannerImage,
            service: res.data[key].data[0].service,
            about: res.data[key].data[0].about,
            work: res.data[key].data[0].work,
          };
          tempData.push(data);
        }
        setData(tempData[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(getHomePageData, []);

  const getServicePageData = () => {
    axios
      .get('https://call4china-default-rtdb.firebaseio.com/servicePage.json')
      .then((res) => {
        const tempData = [];
        const voiceData = [];
        const smsData = [];

        for (const key in res.data) {
          const data = {
            id: key,
            service: res.data[key].service,
            sms: res.data[key].sms,
            voice: res.data[key].voice,
          };
          tempData.push(data.service);
          voiceData.push(data.voice);
          smsData.push(data.sms);
        }

        setDataService(tempData[0]);
        setDataSms(smsData[0]);
        setDataVoice(voiceData[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(getServicePageData, []);

  const getAboutPageData = () => {
    axios
      .get('https://call4china-default-rtdb.firebaseio.com/aboutPage.json')
      .then((res) => {
        const tempData = [];
        for (const key in res.data) {
          const data = {
            id: key,
            title: res.data[key].title,
            image: res.data[key].image,
            desc: res.data[key].desc,
          };
          tempData.push(data);
        }
        setDataAbout(tempData[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(getAboutPageData, []);

  const getContactPageData = () => {
    axios
      .get('https://call4china-default-rtdb.firebaseio.com/contactPage.json')
      .then((res) => {
        const tempData = [];
        for (const key in res.data) {
          const data = {
            id: key,
            title: res.data[key].title,
            image: res.data[key].image,
            desc: res.data[key].desc,
          };
          tempData.push(data);
        }
        setDataContact(tempData[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(getContactPageData, []);

  const getWorkPageData = () => {
    axios
      .get('https://call4china-default-rtdb.firebaseio.com/workPage.json')
      .then((res) => {
        const tempData = [];
        for (const key in res.data) {
          const data = {
            id: key,
            title: res.data[key].title,
            image: res.data[key].image,
            desc: res.data[key].desc,
          };
          tempData.push(data);
        }
        setDataWork(tempData[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(getWorkPageData, []);

  return (
    <CallContext.Provider
      value={{
        data: data,
        setData: setData,
        dataService: dataService,
        setDataService: setDataService,
        dataSms: dataSms,
        setDataSms: setDataSms,
        dataVoice: dataVoice,
        setDataVoice: setDataVoice,
        dataAbout: dataAbout,
        setDataAbout: setDataAbout,
        dataContact: dataContact,
        setDataContact: setDataContact,
        dataWork: dataWork,
        setDataWork: setDataWork,
      }}>
      {children}
    </CallContext.Provider>
  );
};
export const useCallContext = () => {
  return useContext(CallContext);
};
