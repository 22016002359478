import React, { useEffect, useRef } from 'react';
import styles from '../css/contact.module.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import { showDialog } from '../helpers';
import axios from 'axios';

const Contact = () => {
  const { t } = useTranslation();
  const nameRef = useRef();
  const emailRef = useRef();
  const subjectRef = useRef();
  const messageRef = useRef();

  const checkFields = () => {
    if (
      nameRef.current.value != '' ||
      emailRef.current.value != '' ||
      subjectRef.current.value != '' ||
      messageRef.current.value != ''
    ) {
      return true;
    }
    showDialog('Error', 'Enter ALl Credentials', 'error');
    return false;
  };

  const sendMail = () => {
    const message = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      subject: subjectRef.current.value,
      message: messageRef.current.value,
    };

    var formData = new FormData();
    formData.append('name', nameRef.current.value);
    formData.append('email', emailRef.current.value);
    formData.append('supject', subjectRef.current.value);
    formData.append('message', messageRef.current.value);

    axios({
      method: 'post',
      url: 'https://api.call4china.com/api/send/email-connect-us',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((res) => {
        showDialog('Success', 'Successfully Sent', 'success');
      })
      .catch((err) => {
        showDialog('Error', 'Faild', 'error');
      });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    checkFields() && sendMail();
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section
      className={styles.contactSection}
      data-aos='fade-up'
      data-aos-duration='1000'>
      <div className={styles.formTitle}>{t('contact-us')}</div>
      <form onSubmit={onSubmitHandler}>
        <div className={styles.formContainer}>
          <input type='text' id='name' placeholder={t('name')} ref={nameRef} />
          <input
            type='email'
            id='email'
            placeholder={t('email')}
            ref={emailRef}
          />
        </div>
        <div className={styles.formContainer}>
          <input
            type='text'
            id='subject'
            placeholder={t('subject')}
            className={styles.subject}
            ref={subjectRef}
          />
        </div>
        <div className={styles.formContainer}>
          <textarea
            name='message'
            id='message'
            rows={5}
            placeholder={t('message')}
            ref={messageRef}
          />
        </div>
        <div className={styles.formContainer}>
          <input type='submit' value={t('send')} />
        </div>
      </form>
    </section>
  );
};

export default Contact;
