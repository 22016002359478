import React from 'react';
import '../css/header.css';
import Image from '../img/Untitled-1.webp';
import { FaFacebook, FaSkype } from 'react-icons/fa';
import { MdOutlineMailOutline } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();
  return (
    <header>
      <div className='header-container'>
        <div className='header-logo'>
          <div className='logo'>
            <img src={Image} alt='logo' />
          </div>
          <p> {t('Telecom Company Limited')}</p>
        </div>
        <ul className='social-media-header'>
          <li className='skype'>
            <a
              href='skype:live:.cid.4fadfd2fc41b777e?chat'
              rel='noreferrer'
              target='_blank'>
              <FaSkype />
            </a>
          </li>
          <li className='facebook'>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://www.facebook.com/people/Call-China/pfbid02nCY3oQht2QqV8mpYyhCRjwUgWhJMj8haYvsrhAwFi1B5PZjzhcNBxDFBb8AoEH5Xl/'>
              <FaFacebook />
            </a>
          </li>
          <li className='email'>
            <a
              target='_blank'
              rel='noreferrer'
              href='mailto:support@call4china.com'>
              <MdOutlineMailOutline />
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
