import React from 'react';
import Contact from '../components/Contact';
import Main from '../components/pageComponents/Main';
import { useCallContext } from '../context/CallContext';

const ContactPage = () => {
  const { dataContact } = useCallContext();

  return (
    <>
      <Main data={dataContact} title={'contact-us'} />
      <Contact />
    </>
  );
};

export default ContactPage;
