import React from 'react';
import '../css/footer.css';
import Logo from '../img/Untitled-1.webp';
import { FaSkype, FaFacebook } from 'react-icons/fa';
import { MdOutlineMailOutline } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FacebookImg from '../img/facebook.svg';
import SkypeImg from '../img/skype.svg';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <section className='footer-container'>
      <div className='footer'>
        <div className='footer-lists'>
          <ul className='list-nav'>
            <li>
              <Link to='/'>{t('home')}</Link>{' '}
            </li>
            <li>
              <Link to='/services'>{t('services')}</Link>{' '}
            </li>
            <li>
              <Link to='/about-us'>{t('about-us')}</Link>{' '}
            </li>
            <li>
              <Link to='/contact-us'>{t('contact-us')}</Link>{' '}
            </li>
            <li>
              <Link to='/work-us'>{t('start-work')}</Link>{' '}
            </li>
          </ul>
          <ul className='service-list'>
            <li>{t('services')}</li>
            <li>
              <Link to='/services/voice'>{t('voice')}</Link>{' '}
            </li>
            <li>
              <Link to='/services/sms'>{t('sms')}</Link>{' '}
            </li>
          </ul>
        </div>
        <div className='footer-subscribe'>
          <div className='footer-logo'>
            <img src={Logo} alt='logo' />
          </div>

          <div className='social'>
            <ul className='social-media-footer'>
              <li className='skype'>
                <a
                  href='skype:live:.cid.4fadfd2fc41b777e?chat'
                  rel='noreferrer'
                  target='_blank'>
                  <FaSkype />
                </a>
              </li>
              <li className='facebook'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.facebook.com/people/Call-China/pfbid02nCY3oQht2QqV8mpYyhCRjwUgWhJMj8haYvsrhAwFi1B5PZjzhcNBxDFBb8AoEH5Xl/'>
                  <FaFacebook />
                </a>
              </li>
              <li className='email'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='mailto:support@call4china.com'>
                  <MdOutlineMailOutline />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='qrQode'>
          <h3>{t('skype-contact')}</h3>
          <div>
            <img src={FacebookImg} alt='' />
            <img src={SkypeImg} alt='' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
