import React, { useEffect } from 'react';
import Image from '../img/aboutus.webp';
import '../css/aboutUs.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

const AboutUs = ({ data }) => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className='about-container'>
      <div className='about'>
        <div className='about-img' data-aos='fade-left' data-aos-duration='500'>
          <img src={data && data.img} alt='' />
        </div>
        <div
          className='about-text'
          data-aos='fade-right'
          data-aos-duration='500'>
          <h2>{t('about-us')}</h2>
          <p>{data && data.desc}</p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
