import React from 'react';
import Service from '../components/Service';
import AboutUs from '../components/AboutUs';
import WorkUs from '../components/WorkUs';
import Contact from '../components/Contact';
import { useCallContext } from '../context/CallContext';

const HomePage = () => {
  const { data } = useCallContext();

  return (
    <>
      <Service data={data.service} />
      <AboutUs data={data.about} />
      <WorkUs data={data.work} />
      <Contact />
    </>
  );
};

export default HomePage;
