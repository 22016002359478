import { Suspense } from 'react';

import HomePage from './pages/HomePage';
import './translating';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Nav from './components/Nav';
import Slider from './components/Slider';
import Footer from './components/Footer';
import ServicesPage from './pages/ServicesPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import WorkPage from './pages/WorkPage';
import Main from './components/pageComponents/Main';
import Loader from './components/loader';
import { useCallContext } from './context/CallContext';

function App() {
  const { data, dataService, dataSms, dataVoice } = useCallContext();

  return (
    <Suspense fallback={<Loader />}>
      <Header />
      <Nav />
      {data && <Slider images={data.bannerImage} />}
      <Routes>
        <Route path='/' element={<HomePage data={data} />} />
        <Route path='services' element={<ServicesPage />}>
          <Route
            index
            element={
              dataService && <Main data={dataService} title={'services'} />
            }
          />
          <Route
            path='voice'
            element={dataVoice && <Main data={dataVoice} title={'voice'} />}
          />
          <Route
            path='sms'
            element={dataSms && <Main data={dataSms} title={'sms'} />}
          />
        </Route>
        <Route path='about-us' element={<AboutPage />} />
        <Route path='contact-us' element={<ContactPage />} />
        <Route path='work-us' element={<WorkPage />} />
      </Routes>

      <Footer />
    </Suspense>
  );
}

export default App;
