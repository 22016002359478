import React, { useEffect } from 'react';
import '../css/service.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Service = ({ data }) => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className='service-container'>
      <div className='service'>
        <div
          className='service-text'
          data-aos='fade-left'
          data-aos-duration='500'>
          <h2>{t('services')}</h2>
          <p>{data && data.desc}</p>
          <Link to='/services' className='button'>
            {t('read-more')} <span>{'>>'}</span>
          </Link>
        </div>
        <div
          className='service-img'
          data-aos='fade-right'
          data-aos-duration='500'>
          <img src={data && data.img} alt='' />
        </div>
      </div>
    </section>
  );
};

export default Service;
