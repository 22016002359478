import React from 'react';
import Contact from '../components/Contact';
import Main from '../components/pageComponents/Main';
import { useCallContext } from '../context/CallContext';

const AboutPage = () => {
  const { dataAbout } = useCallContext();

  return (
    <>
      <Main data={dataAbout} title={'about-us'} />
      <Contact />
    </>
  );
};

export default AboutPage;
