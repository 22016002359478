import React from 'react';
import Contact from '../components/Contact';
import Main from '../components/pageComponents/Main';
import { useCallContext } from '../context/CallContext';

const WorkPage = () => {
  const { dataWork } = useCallContext();

  return (
    <>
      <Main data={dataWork} title={'work-us'} />
      <Contact />
    </>
  );
};

export default WorkPage;
