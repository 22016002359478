import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Aos from 'aos';

const Main = ({ data, title }) => {
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <Section>
      <div className='main'>
        <div className='main-text' data-aos='fade-left' data-aos-duration='500'>
          <h2>{t(title)}</h2>
          <p>{data && data.desc}</p>
        </div>
        <div className='main-img' data-aos='fade-right' data-aos-duration='500'>
          <img src={data && data.image} alt={title} />
        </div>
      </div>
    </Section>
  );
};
const Section = styled.section`
  width: 100%;
  height: auto;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-black);

  box-shadow: -2px -10px 3px -6px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: -2px -10px 3px -6px rgba(0, 0, 0, 0.75) inset;
  -moz-box-shadow: -2px -10px 3px -6px rgba(0, 0, 0, 0.75) inset;
  .main {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  .main-text {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h2 {
      font-size: 1.8rem;
      font-family: monospace;
      font-weight: bold;
      margin-bottom: 1rem;

      text-shadow: 1px 1px #000;
    }
    p {
      text-align: justify;
      font-size: 1.2rem;
      color: rgba(255, 255, 255, 0.7);
    }
  }
  .main-img {
    flex: 1;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      box-shadow: 15px 14px 3px -6px rgba(0, 0, 0, 0.74);
      -webkit-box-shadow: 15px 14px 3px -6px rgba(0, 0, 0, 0.74);
      -moz-box-shadow: 15px 14px 3px -6px rgba(0, 0, 0, 0.74);
    }
  }
  @media (max-width: 700px) {
    .main {
      flex-direction: column;
      gap: 1rem;
    }
  }
  @media (max-width: 380px) {
    .main {
      width: 100%;
    }
    .main-img {
      width: 80%;
    }
    .main-text {
      width: 80%;
    }
  }
`;

export default Main;
