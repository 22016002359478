import React from 'react';
import Contact from '../components/Contact';

import { Outlet } from 'react-router-dom';

const ServicesPage = () => {
  return (
    <>
      <Outlet />
      <Contact />
    </>
  );
};

export default ServicesPage;
