import React, { useEffect } from 'react';
import Image from '../img/work.jpg';
import '../css/workUs.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const WorkUs = ({ data }) => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className='work-container'>
      <div className='work'>
        <div className='work-text' data-aos='fade-left' data-aos-duration='500'>
          <h2>{t('How can you work with us ?')}</h2>
          <p>{data && data.desc}</p>
          <Link to='/work-us'>{t('more-question')} </Link>
        </div>

        <div className='work-img' data-aos='fade-right' data-aos-duration='500'>
          <img src={data && data.img} alt='' />
        </div>
      </div>
    </section>
  );
};

export default WorkUs;
