import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../css/slider.css';

import { Pagination, Navigation, Autoplay } from 'swiper';

const Slider = ({ images }) => {
  return (
    <section className='slider-container'>
      <div className='slider'>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          slidesPerView={1}
          navigation
          autoplay={{ delay: 3000 }}
          className='mySwiper'>
          {images &&
            images.map((img, index) => (
              <SwiperSlide key={index}>
                <img src={img} alt='' />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Slider;
